<template>
	<el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-width="$root.rwd < 3 ? '130px' : ''" :label-position="$root.rwd > 2 ? 'top' : 'left'">
		<el-form-item label="帳號" prop="account">
			<el-input v-model="ruleForm.account" placeholder="８碼以上英文字母與半形數字" class="round" />
		</el-form-item>
		<el-form-item label="密碼" prop="password">
			<el-input v-model="ruleForm.password" placeholder="密碼" show-password class="round" />
		</el-form-item>
		<el-form-item label="請再次輸入密碼" prop="repeat_password" :rules="validatePassword(ruleForm)">
			<el-input v-model="ruleForm.repeat_password" placeholder="請再次輸入密碼" show-password class="round" />
		</el-form-item>
		<el-form-item label="信箱" prop="email">
			<el-input v-model="ruleForm.email" placeholder="電子信箱" class="round" />
		</el-form-item>
		<el-form-item label="姓名" prop="name">
			<div class="row-grid" gutter-x="30" gutter-y="20" align-y="center">
				<div>
					<el-input v-model="ruleForm.name" placeholder="姓名" class="round" />
				</div>
				<el-form-item prop="gender" style="margin:0!important;">
					<div class="row-grid" align-y="center" gutter-y="10">
						<el-radio v-model="ruleForm.gender" label="先生" />
						<el-radio v-model="ruleForm.gender" label="女士" />
						<el-radio v-model="ruleForm.gender" label="其他" />
					</div>
				</el-form-item>
			</div>
		</el-form-item>
		<el-form-item label="出生年" prop="birthday">
			<el-date-picker v-model="ruleForm.birthday" value-format="timestamp" type="year" placeholder="選擇年份" class="round" />
		</el-form-item>
		<el-form-item label="手機號碼" prop="cellphone">
			<el-input v-model="ruleForm.cellphone" class="round" />
		</el-form-item>
		<el-form-item label="聯絡電話" prop="phone">
			<el-input v-model="ruleForm.phone" class="round" />
		</el-form-item>
		<el-form-item label="通訊地址" required>
			<div class="row-grid" gutter-x="10">
				<el-form-item prop="zipcode">
					<el-input v-model="ruleForm.zipcode" placeholder="郵遞區號" style="width:auto;" class="round" />
				</el-form-item>
				<el-form-item prop="city">
					<el-select v-model="ruleForm.city" placeholder="縣市" class="round" @change="$set(ruleForm, 'district', '')">
						<template v-for="(val, key, i) in administrativeDistrict">
							<el-option :value="key" :key="i" />
						</template>
					</el-select>
				</el-form-item>
				<el-form-item prop="district">
					<el-select v-model="ruleForm.district" placeholder="鄉鎮市區" class="round">
						<template v-for="(district, i) in (administrativeDistrict[ruleForm.city] || new Array)">
							<el-option :value="district" :key="i" />
						</template>
					</el-select>
				</el-form-item>
			</div>
			<el-form-item prop="address">
				<el-input v-model="ruleForm.address" placeholder="里(村)/路(街)/號/樓(室)" class="round" />
			</el-form-item>
		</el-form-item>
		<el-form-item label="訂閱">
			<el-checkbox v-model="ruleForm.subscribe" label="我同意收到金枝電子報" />
		</el-form-item>
	</el-form>
</template>

<script>
import { rule } from "@/utils/shared-methods";
import { mapState } from "vuex";
import administrativeDistrict from "@/assets/data/administrative-district.json";
export default {
	data() {
		return {
			administrativeDistrict,
			ruleForm: new Object,
			rules: {
				account: rule(/^[a-zA-Z0-9]{8,}$/, "８碼以上英文字母與半形數字"),
				email: [
					{ required: true, message: "必填" },
					{ type: "email", message: "請填正確信箱" }
				],
				password: rule(/[a-zA-Z0-9]{8,}/, "８碼以上英文字母與半形數字"),
				name: rule(),
				gender: rule(),
				birthday: rule(),
				cellphone: rule(/^[0-9]{10}$/, "請填寫正確手機（10位數字）"),
				city: rule(),
				district: rule(),
				zipcode: rule(),
				address: rule(),
			},
		}
	},
	computed: {
		...mapState("auth", ["_auth"])
	},
	methods: {
		validatePassword({ password, repeat_password }) {	// 驗證重複密碼
			return [
				{ required: true, message: "必填" },
				{
					validator: (rule, value, callback) => {
						if (password === repeat_password) callback();
						else callback(new Error("兩次密碼不一樣"));
					}
				}
			]
		},
		async validate() {
			return new Promise((resolve, reject) => {
				this.$refs["ruleForm"].validate((valid, object) => {
					if (valid) resolve(this.ruleForm);
					else reject("請確認表格皆填寫完畢");
				});
			});
		},
		resetFields() {
			this.ruleForm = new Object;
			this.$refs["ruleForm"].resetFields();
		}
	},
	created() {
		if (this._auth) this.$set(this.$data, "ruleForm", this._auth);
	}
}
</script>